<template>
  <div class="animated fadeIn">
    <b-card>
      <template v-slot:header>
        <h5>Unggah Bukti Pekerjaan</h5>
      </template>
      <div class="row">
        <div class="col-12">
          <span class="mb-1 d-block">Foto Bukti <sup class="text-danger">*</sup></span>
        </div>
        <div class="col-6 col-sm-4 col-md-3 col-xl-2 mb-3" v-for="item in imagePreview" :key="item.preview">
          <div class="image-preview-container">
            <button @click="removePreviewImage(item.preview, item.file)" class="btn-remove-image">
              <i class="fa fa-times"></i>
            </button>
            <expandable-image class="image-preview" :src="item.preview" />
          </div>
        </div>
        <div class="col-6 col-sm-4 col-md-3 col-xl-2 mb-3">
          <label for="images" class="choose-image-action">
            <input type="file" accept="image/*" id="images" class="d-none" multiple @change="handleChangeImages">
            <i class="fa fa-image icon-choose"></i>
          </label>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-6 col-md-4">
          <span class="mb-1 d-block">Video Bukti <sup>optional</sup></span>
          <div class="form-group wrapper-video-view">
            <label for="video">
              <input @change="handleChangeVideo" type="file" id="video" class="d-none" accept="video/*">
              <div class="icon-choose-video d-nonex">
                <i class="fa fa-file-video-o icon-choose"></i>
              </div>
            </label>
            <div v-if="videoPreview" class="video-preview">
              <button @click="removeVideoSelected" class="btn-remove-video">
                <i class="fa fa-times"></i>
              </button>
              <video controls>
                <source :src="videoPreview" type="video/mp4">
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>
      </div>
      <div v-if="form.files.length">
        <b-button :disabled="isLoading" @click="handleSubmitBukti" variant="pgiBtn">
          <i class="fa fa-upload" v-if="!isLoading"></i>
          <i class="fa fa-spin fa-spinner" v-if="isLoading"></i>
          Upload Bukti
        </b-button>
      </div>
    </b-card>

    <!-- Modal Rating -->
    <b-modal id="m-rating" size="md" title="Ulasan" ref="my-modal-rating" no-close-on-backdrop no-close-on-esc hide-footer
      hide-header>
      <b-form @submit.prevent="onSubmitRating">
        <div class="text-center">
          <star-rating v-model="formRating.rating" value="0" :show-rating="false" :glow="10"
            :rounded-corners="true"></star-rating>
          <small v-if="!$v.formRating.rating.required && $v.formRating.rating.$dirty" class="text-danger">
            File tidak boleh kosong
          </small>
        </div>

        <b-form-group class="mt-5" id="fg-create-ulasan" label="Tulis ulasan mu disini ya :)">
          <textarea class="textarea form-control" placeholder="Ulasan" v-model.trim="formRating.keterangan"
            :rows="4"></textarea>
        </b-form-group>

        <div class="form-wrapper-btn text-center">
          <b-button type="submit" variant="primary" class="mt-5 mr-2 ml-2 btn-labeled"
            :disabled="this.$v.formRating.$invalid">
            <i class="fa fa-paper-plane mr-2"></i>
            Kirim Ulasan
          </b-button>
        </div>
      </b-form>
    </b-modal>
    <!-- End Modal Rating -->
  </div>
</template>

<script>
import { validationMixin } from "/node_modules/vuelidate";
import { required } from "/node_modules/vuelidate/lib/validators";
export default {
  mixins: [validationMixin],
  data: () => {
    return {
      isLoading: false,
      formRating: {
        id: null,
        rating: null,
        keterangan: "",
      },
      form: {
        files: [],
        video: [],
      },
      videoPreview: null,
      imagePreview: [],
    };
  },
  validations: {
    formRating: {
      rating: {
        required,
      },
    },
  },

  methods: {
    handleChangeImages(e) {
      this.fileErrorMessage = ''
      const files = e.target.files;
      this.createPreviewImage(files)
    },
    createPreviewImage(files) {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        this.form.files.push(file);
        let blobURL = URL.createObjectURL(file);
        this.imagePreview.push({
          preview: blobURL,
          file: file
        })
      }
    },
    createPreviewVideo(file) {
      let blobURL = URL.createObjectURL(file);
      this.videoPreview = blobURL
    },
    removeVideoSelected() {
      this.videoPreview = null
      this.form.video = []
    },
    removePreviewImage(preview, file) {
      const newPreview = this.imagePreview.filter(i => i.preview !== preview)
      const newFile = []
      for (let i = 0; i < this.form.files.length; i++) {
        const item = this.form.files[i];
        if (item === file) {
          newFile.push(item)
        }
      }
      this.imagePreview = newPreview
      this.form.files = newFile

      // console.log(newPreview.length)
    },
    handleChangeVideo(e) {
      this.fileErrorMessage = ''
      const videoFile = e.target.files[0]
      this.form.video = e.target.files
      this.createPreviewVideo(videoFile)
    },

    async handleSubmitBukti() {
      const id = this.$route.params.id;
      const form = new FormData()
      const files = this.form.files;
      const videos = this.form.video;
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        form.append(`files[${i}]`, file)
      }
      for (let i = 0; i < videos.length; i++) {
        const video = videos[i];
        form.append(`video[${i}]`, video)
      }

      this.isLoading = true
      this.$axios
        .post(`/api/internal-memo/memo/uploadBuktiPic/${id}`, form, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }).then(res => {
          console.log(res)
          this.$bvModal.show("m-rating");
          this.isLoading = false
        }).catch(err => {
          console.log(err)
          this.isLoading = false
        })
    },
    async onSubmitRating() {
      const id = this.$route.params.id
      this.isLoading = true
      this.$axios.post(`/api/internal-memo/memo/createInternalRating/${id}`, {
        rating: this.formRating.rating,
        keterangan: this.formRating.keterangan,
      }).then(response => {
        this.$helper.toastSucc(this, "Sukses membuat rating!")
        this.isLoading = false
        this.$router.push({
          path: '/daftar-pengajuan'
        })
      }).catch(err => {
        this.$helper.toastErr(this, "Gagal membuat rating!")
        this.isLoading = false
      })
    }
  },

  created() {

  },
};
</script>

